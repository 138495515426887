import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { Link } from "gatsby"

import { MdArrowForward } from "react-icons/md"
import DotsFive from "../../dashboard/assets/DotsFive"
import DotsSix from "../../dashboard/assets/DotsSix"
import Layout from "../../layouts"
import SEO from "../../components/seo"
import Header from "../../components/header"
import {
  EmphaticHeading,
  Heading,
} from "../../dashboard/components/shared/Typography"
import { PrimaryButton } from "../../dashboard/components/shared/Buttons"
import {
  Text as BaseText,
  Lede as BaseLede,
} from "../../dashboard/components/shared/Typography"
import {
  breakpoints,
  fontSizes,
  fontFamilies,
  palette,
  spaces,
} from "../../dashboard/utils/presets"

const DotsLeft = styled(DotsFive)`
  height: 19.5rem;
  left: 0;
  max-height: 45%;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    opacity: 0.6;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    opacity: 1;
    max-height: 100%;
  }
`
const DotsRight = styled(DotsSix)`
  position: absolute;
  height: 11.5rem;
  max-height: 28%;
  opacity: 0.2;
  right: 0;
  top: 50%;
  width: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    opacity: 0.4;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
    max-height: 100%;
    opacity: 1;
    top: 5rem;
  }
`

const PageHeader = styled(`header`)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${spaces[`4xl`]} 0 0;
  position: relative;
  z-index: 1;

  ${Heading} {
    font-size: ${fontSizes[`2xl`]};
    position: relative;
  }

  p {
    margin: 0;
  }

  ${EmphaticHeading} {
    text-align: center;
    font-size: ${fontSizes[`5xl`]};
    margin-top: ${spaces.l};
    position: relative;
  }

  ${PrimaryButton} {
    background: ${palette.yellow[500]};
    color: ${palette.grey[900]};
    margin-top: ${spaces.xl};
    position: relative;

    :hover {
      background: ${palette.purple[600]};
      color: ${palette.yellow[500]};
    }
  }
`

const Note = styled(`div`)`
  color: ${palette.grey[600]};
  font-style: italic;
  font-size: ${fontSizes.xs};
  margin: ${spaces.xl} auto ${spaces.m};
  max-width: 100%;
  padding: 0 ${spaces.l};
  text-align: center;
  width: 28rem;

  p {
    margin: 0;
  }
`

const Logos = styled(`div`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 ${spaces.l};

  p {
    margin: 0;
  }

  img {
    width: auto;
    height: ${spaces.m};
    margin: ${spaces.s};
    margin-bottom: 0;
  }
`

const Body = styled(`div`)`
  margin: ${spaces[`2xl`]} auto ${spaces[`4xl`]};
  max-width: 100%;
  padding: 0 ${spaces.l};
  width: 38em;
`

const Lede = styled(BaseLede.withComponent(`div`))`
  font-family: ${fontFamilies.headerFontFamily} !important; /* todo: investigate the hack */
  font-weight: bold;
  font-size: ${fontSizes.l};
  line-height: 1.25;
  margin-bottom: ${spaces.xl};
`

const Text = BaseText.withComponent(`div`)

const Links = styled(`ul`)`
  border-top: 1px solid ${palette.grey[200]};
  line-height: 1.4;
  list-style: none;
  margin: ${spaces[`3xl`]} 0;
  padding-top: ${spaces.l};

  li {
    margin-bottom: ${spaces.xs};
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  svg {
    vertical-align: text-top;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    li {
      margin-bottom: ${spaces[`2xs`]};
    }
  }
`

const PreviewLanding = ({ data, location }) => {
  const { nav, contentfulPage } = data
  const {
    title,
    subtitle: { subtitle },
    quickLinks,
    sentenceLinks,
    contentBlocks,
  } = contentfulPage

  const { url: ctaUrl, displayTitle: ctaLabel } = quickLinks[0]

  const contentBlocksHTML = contentBlocks.reduce((blocks, block) => {
    blocks[block.contentfulid] = block.body.childMarkdownRemark.html
    return blocks
  }, {})

  return (
    <Layout pathname={location.pathname}>
      <SEO contentfulPage={contentfulPage} />
      <Header navItems={nav.edges} />
      <PageHeader>
        <DotsLeft />
        <DotsRight />
        <Heading>{title}</Heading>
        <EmphaticHeading
          dangerouslySetInnerHTML={{
            __html: subtitle.replace(/(?:\r\n|\r|\n)/g, `<br />`),
          }}
        />
        <PrimaryButton size={`XL`} to={ctaUrl}>
          {ctaLabel} <MdArrowForward />
        </PrimaryButton>
        <Note
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_ctaNote,
          }}
        />
        <Logos
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_logos,
          }}
        />
      </PageHeader>
      <Body>
        <Lede
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_lede,
          }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_text,
          }}
        />

        <Links>
          {sentenceLinks.map(item => {
            const { sentence, anchorText, url } = item
            const outerLink = url.includes(`http`)
            return (
              <li key={anchorText}>
                {sentence}
                {` `}
                {!outerLink ? (
                  <Link to={url}>
                    {anchorText} <MdArrowForward />
                  </Link>
                ) : (
                  <a href={url}>
                    {anchorText} <MdArrowForward />
                  </a>
                )}
              </li>
            )
          })}
        </Links>
      </Body>
    </Layout>
  )
}

PreviewLanding.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default PreviewLanding

export const pageQuery = graphql`
  query ContentfulPreviewLanding($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      quickLinks {
        displayTitle
        url
      }
      sentenceLinks {
        sentence
        anchorText
        url
      }
      ...ContentBlocks
    }
  }
`
