import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    width="264"
    height="275"
    viewBox="0 0 264 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.25">
      <g style={{ mixBlendMode: `multiply` }}>
        <rect
          x="207.417"
          y="84.0574"
          width="106.069"
          height="22.0144"
          rx="11.0072"
          transform="rotate(90 207.417 84.0574)"
          fill="#FFF5BF"
        />
      </g>
      <ellipse
        cx="196.41"
        cy="123.083"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 196.41 123.083)"
        fill="#FFDF37"
      />
      <ellipse
        cx="168.389"
        cy="151.101"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 168.389 151.101)"
        fill="#BC027F"
      />
      <g style={{ mixBlendMode: `multiply` }}>
        <ellipse
          cx="196.411"
          cy="179.12"
          rx="11.007"
          ry="11.007"
          transform="rotate(180 196.411 179.12)"
          fill="#B17ACC"
        />
      </g>
      <ellipse
        cx="168.391"
        cy="67.0447"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 168.391 67.0447)"
        fill="#59C156"
      />
      <circle
        cx="196.41"
        cy="95.0656"
        r="11.0082"
        transform="rotate(180 196.41 95.0656)"
        fill="#FB8400"
      />
      <ellipse
        cx="168.391"
        cy="95.0656"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 168.391 95.0656)"
        fill="#663399"
      />
      <circle
        cx="252.445"
        cy="95.0668"
        r="11.007"
        transform="rotate(180 252.445 95.0668)"
        fill="#05F7F4"
      />
      <circle
        cx="223.425"
        cy="123.082"
        r="11.0082"
        transform="rotate(180 223.425 123.082)"
        fill="#FFD280"
      />
      <ellipse
        rx="11.0082"
        ry="11.0082"
        transform="matrix(-1 1.74846e-07 1.74845e-07 1 56.3167 95.0637)"
        fill="#FFDF37"
      />
      <ellipse
        rx="11.0082"
        ry="11.0082"
        transform="matrix(-1 1.74845e-07 1.74846e-07 1 28.301 67.0467)"
        fill="#00BDB6"
      />
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="39.3075"
          y="218.145"
          width="106.069"
          height="22.0144"
          rx="11"
          transform="rotate(-180 39.3075 218.145)"
          fill="#3FA9F5"
        />
      </g>
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          width="134.088"
          height="22.0144"
          rx="11"
          transform="matrix(1 0 0 -1 17.2914 78.0529)"
          fill="#3FA9F5"
        />
      </g>
      <ellipse
        rx="11.007"
        ry="11.007"
        transform="matrix(-1 1.74846e-07 1.74846e-07 1 140.374 67.0455)"
        fill="#159BF3"
      />
      <circle
        r="11.007"
        transform="matrix(-1 1.74846e-07 1.74846e-07 1 56.3178 39.0274)"
        fill="#F2C4E3"
      />
      <ellipse
        rx="11.0082"
        ry="11.0082"
        transform="matrix(-1 1.74845e-07 1.74846e-07 1 28.301 151.1)"
        fill="#FF5A54"
      />
      <ellipse
        rx="11.0082"
        ry="11.0082"
        transform="matrix(-1 1.74846e-07 1.74846e-07 1 84.3342 123.083)"
        fill="#A1DA9E"
      />
      <ellipse
        rx="11.0082"
        ry="11.0082"
        transform="matrix(-1 1.74845e-07 1.74846e-07 1 28.301 123.083)"
        fill="#CCFFFC"
      />
      <ellipse
        rx="11.007"
        ry="11.007"
        transform="matrix(-1 1.74846e-07 1.74846e-07 1 112.355 123.082)"
        fill="#159BF3"
      />
      <circle
        r="11.0082"
        transform="matrix(-1 1.74846e-07 1.74846e-07 1 84.3342 95.0637)"
        fill="#FB8400"
      />
      <ellipse
        cx="28.301"
        cy="207.137"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 28.301 207.137)"
        fill="#FFDF37"
      />
      <ellipse
        cx="0.279437"
        cy="235.156"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 0.279437 235.156)"
        fill="#BC027F"
      />
      <circle
        cx="112.355"
        cy="235.157"
        r="11.007"
        transform="rotate(180 112.355 235.157)"
        fill="#159BF3"
      />
      <ellipse
        cx="28.3023"
        cy="263.175"
        rx="11.007"
        ry="11.007"
        transform="rotate(180 28.3023 263.175)"
        fill="#B17ACC"
      />
      <ellipse
        cx="0.281384"
        cy="151.1"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 0.281384 151.1)"
        fill="#59C156"
      />
      <circle
        cx="28.301"
        cy="179.12"
        r="11.0082"
        transform="rotate(180 28.301 179.12)"
        fill="#FB8400"
      />
      <ellipse
        cx="0.281384"
        cy="179.12"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(180 0.281384 179.12)"
        fill="#663399"
      />
      <circle
        cx="84.3354"
        cy="179.121"
        r="11.007"
        transform="rotate(180 84.3354 179.121)"
        fill="#05F7F4"
      />
      <circle
        cx="56.315"
        cy="207.136"
        r="11.0082"
        transform="rotate(180 56.315 207.136)"
        fill="#FFD280"
      />
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="39.3075"
          y="106.071"
          width="162.106"
          height="22.0144"
          rx="11"
          transform="rotate(-180 39.3075 106.071)"
          fill="url(#paint0_linear)"
        />
      </g>
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="39.3075"
          y="134.09"
          width="330.216"
          height="22.0144"
          rx="11"
          transform="rotate(-180 39.3075 134.09)"
          fill="url(#paint1_linear)"
        />
      </g>
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="229.432"
          y="134.09"
          width="156.102"
          height="22.0144"
          rx="11"
          transform="rotate(-180 229.432 134.09)"
          fill="url(#paint2_linear)"
        />
      </g>
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="41.3081"
          y="162.108"
          width="192.126"
          height="22.0144"
          rx="11"
          transform="rotate(-180 41.3081 162.108)"
          fill="#59C156"
        />
      </g>
      <g style={{ mixBlendMode: `multiply` }} opacity="0.1">
        <rect
          x="39.3075"
          y="190.127"
          width="162.106"
          height="22.0144"
          rx="11"
          transform="rotate(-180 39.3075 190.127)"
          fill="#FB8400"
        />
      </g>
      <ellipse
        cx="28.2993"
        cy="95.0632"
        rx="11.0082"
        ry="11.0082"
        transform="rotate(-180 28.2993 95.0632)"
        fill="#BC027F"
      />
      <circle
        cx="0.280601"
        cy="39.0283"
        r="11.007"
        transform="rotate(-180 0.280601 39.0283)"
        fill="#159BF3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="201.414"
        y1="128.086"
        x2="39.3075"
        y2="128.086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5A54" />
        <stop offset="1" stopColor="#BC027F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="369.523"
        y1="156.104"
        x2="39.3075"
        y2="156.104"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#05F7F4" />
        <stop offset="1" stopColor="#FFDF37" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="385.534"
        y1="156.104"
        x2="229.432"
        y2="156.104"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5A54" />
        <stop offset="1" stopColor="#BC027F" />
      </linearGradient>
    </defs>
  </svg>
)
