import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    width="262"
    height="162"
    viewBox="0 0 262 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle cx="94.999" cy="67" r="11.001" fill="#FFDF37" />
      <circle cx="123.001" cy="39.0005" r="11.001" fill="#BC027F" />
      <circle cx="10.9998" cy="38.9993" r="10.9998" fill="#159BF3" />
      <circle cx="94.9979" cy="10.9998" r="10.9998" fill="#B17ACC" />
      <circle cx="122.999" cy="123.001" r="11.001" fill="#59C156" />
      <circle cx="94.999" cy="94.9995" r="11.001" fill="#FB8400" />
      <circle cx="122.999" cy="94.9995" r="11.001" fill="#663399" />
      <circle cx="38.9998" cy="94.9983" r="10.9998" fill="#05F7F4" />
      <circle cx="68" cy="67.001" r="11.001" fill="#FFD280" />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 234.999 95)"
        fill="#FFDF37"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 262.999 123)"
        fill="#00BDB6"
      />
      <circle
        r="10.9998"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 151 123.001)"
        fill="#159BF3"
      />
      <circle
        r="10.9998"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 234.998 151)"
        fill="#F2C4E3"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 262.999 39.001)"
        fill="#37B635"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 207.001 67.0005)"
        fill="#A1DA9E"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 262.999 67.0005)"
        fill="#CCFFFC"
      />
      <circle
        r="10.9998"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 179 67.0017)"
        fill="#159BF3"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 207.001 95)"
        fill="#FB8400"
      />
    </g>
  </svg>
)
